import React, { useEffect } from "react";
import YouTube, { YouTubePlayer } from "react-youtube";
import SluitenSVG from "./../assets/svg/sluiten.svg";
import { trigger } from "./Events";

const Showreel = ({ isActive = false, setIsActive }) => {
  const [videoPlayer, setVideoPlayer] = React.useState<YouTubePlayer | null>(
    null
  );

  useEffect(() => {
    if (!isActive) {
      stopVideo();
    } else {
      setTimeout(() => {
        playVideo();
      }, 100);
    }

    // wait for escape key to be pressed
    const handleEscape = (e: KeyboardEvent) => {
      if (e.key === "Escape") {
        setIsActive(false);
      }
    };

    window.addEventListener("keydown", handleEscape);

    return () => {
      window.removeEventListener("keydown", handleEscape);
    };
  }, [isActive]);

  const playVideo = () => {
    if (!videoPlayer) return;
    videoPlayer.playVideo();
  };

  const stopVideo = () => {
    if (!videoPlayer) return;
    videoPlayer.stopVideo();
  };

  return (
    <div className={`c-showreel ${isActive ? "c-showreel--active" : ""}`}>
      <div className="c-showreel__button-container">
        <div>
          <button
            title="Sluiten"
            aria-label="Sluiten"
            className="c-showreel__escape-button"
            onClick={() => setIsActive(false)}
            onMouseOver={() => trigger("cursor:click")}
            onMouseLeave={() => trigger("cursor:unclick")}
          >
            <SluitenSVG />
          </button>
        </div>
      </div>
      <YouTube
        opts={{
          playerVars: {
            rel: 0,
            controls: 0,
            modestbranding: 1,
            autoplay: 1,
          },
        }}
        className="c-showreel__video"
        videoId="ckjT8V0lTr8"
        onReady={(e) => setVideoPlayer(e.target)}
        onEnd={() => {
          setTimeout(() => {
            setIsActive(false);
          }, 800);
        }}
      />
    </div>
  );
};

export default Showreel;
