import * as React from "react";

interface Props {
    gif: any;
    gifMobile: any;
}

const ClientCaroussel = (props: Props) => {

    return (
        <>
            <div className={"c-client-caroussel"}>
                <img className="c-client-caroussel__img" src={props.gif} alt="" />
                <img className="c-client-caroussel__img--mobile" src={props.gifMobile} alt="" />
            </div>
        </>
    )
}

export default ClientCaroussel;
