import React from "react";
import VideoTapeSVG from "./../assets/svg/videotape.svg";
import PlaySVG from "./../assets/svg/play.svg";
import VideoTapeImg from "./../images/videotape.png";
import { trigger } from "./Events";

const ShowreelButton = ({setIsActive}) => {
  const handleButtonClick = () => {
    setIsActive(true)
  };

  return (
    <div className="c-showreel-button__wrapper">
      <button
        className="c-showreel-button"
        onMouseOver={() => trigger("cursor:click")}
        onMouseOut={() => trigger("cursor:unclick")}
        onClick={handleButtonClick}
      >
        <span className="c-showreel-button__image__wrapper">
          <span className="c-showreel__play">
            <PlaySVG className="c-showreel__play-icon" />
          </span>
          <img
            src={VideoTapeImg}
            alt="showreel"
          />
        </span>
        <span className="c-showreel-button__text">Showreel</span>
        <VideoTapeSVG className="c-showreel-button-icon--ticket" />
        <PlaySVG className="c-showreel-button-icon--play" />
      </button>
    </div>
  );
};

export default ShowreelButton;
