import * as React from "react";
import {
  useEffect,
  useRef,
  useState,
  RefObject,
  createRef,
  useContext,
} from "react";
import { useMediaQuery } from "react-responsive";
import { graphql, Link } from "gatsby";
import { Textfit } from "react-textfit";
import { gsap, Sine, Quint } from "gsap";
import htmlFromField from "./../lib/htmlFromField";
import MediaBlock from "../components/MediaBlock";
import Navigation from "./../components/Navigation";
import Logo from "../components/Logo";
import { COLOR_ENUM } from "../components/Helpers/Color";
import Cursor from "../components/Cursor";
import { ContextProviderComponent, GlobalContext } from "../components/Context";
import { on, off, trigger } from "../components/Events";
import { getPageTitle } from "../components/Helpers/Meta";
import { Helmet } from "react-helmet";
import ClientCaroussel from "../components/ClientCaroussel";
import ShowreelButton from "../components/ShowreelButton";
import Showreel from "../components/Showreel";

export const HOMEPAGE_QUERY = graphql`
  query HomepageQuery($id: String!) {
    content: contentfulHomepage(id: { eq: $id }) {
      showcases {
        textTop
        textBottom
        description {
          childMarkdownRemark {
            html
            rawMarkdownBody
          }
        }
        image {
          file {
            contentType
            url
          }
          title
        }
      }
      clientsGif {
        file {
          contentType
          url
        }
      }
      clientsGifMobile {
        file {
          contentType
          url
        }
      }
    }
  }
`;

interface HomepageTemplateProp {
  data: any;
}

interface PoeticContentBlockProp {
  secondaryColor: string;
  primaryColor: string;
  logoColorFrom: string;
  logoColorTo: string;
  cursorColor: string;
  showcase: any;
  onClose: any;
}

class PoeticContentBlock extends React.Component {
  ref: RefObject<HTMLDivElement> = createRef();
  textRef: RefObject<HTMLDivElement> = createRef();
  id: string;

  props: PoeticContentBlockProp;

  animateIn({ position }) {
    gsap.killTweensOf(this.ref.current);
    gsap
      .timeline()
      .set(
        this.ref.current,
        {
          display: "flex",
          opacity: 0,
          backgroundColor: this.props.secondaryColor,
          color: this.props.secondaryColor,
        },
        0
      )
      .to(
        this.ref.current,
        {
          opacity: 1,
          duration: 0.4,
          ease: Quint.easeOut,
        },
        position
      )
      .to(
        this.ref.current,
        {
          color: this.props.primaryColor,
          y: 0,
          duration: 0.4,
          ease: Quint.easeOut,
        },
        position + 0.1
      );

    gsap.killTweensOf(this.textRef.current);
    gsap
      .timeline()
      .set(this.textRef.current, {
        y: 50,
      })
      .to(
        this.textRef.current,
        {
          y: 0,
          ease: Quint.easeOut,
        },
        position + 0.1
      );

    gsap.killTweensOf(".c-logo__svg");
    gsap
      .timeline()
      .set(".c-logo__svg", {
        fill: this.props.logoColorFrom,
      })
      .to(
        ".c-logo__svg",
        {
          fill: this.props.logoColorTo,
        },
        position + 0.4
      );

    gsap.killTweensOf(".c-cursor__close");
    gsap.timeline().set(".c-cursor__close", {
      fill: this.props.cursorColor,
    });
  }

  animateOut({ position }) {
    gsap.timeline().to(
      this.ref.current,
      {
        opacity: 0,
        duration: 0.4,
        ease: Quint.easeOut,
        onComplete: () => {
          gsap.to(this.ref.current, {
            display: "none",
          });
        },
      },
      position + 1
    );

    gsap
      .timeline()
      .set(this.textRef.current, {
        y: 0,
      })
      .to(
        this.textRef.current,
        {
          y: 50,
          ease: Quint.easeOut,
        },
        position + 0.1
      );

    gsap
      .timeline()
      .set(".c-logo__svg", {
        fill: this.props.logoColorTo,
      })
      .to(
        ".c-logo__svg",
        {
          fill: this.props.logoColorFrom,
        },
        position + 0.4
      );
  }

  render() {
    const { showcase, onClose } = this.props;

    return (
      <div
        className={"c-homepage--description c-homepage__fixed-content"}
        ref={this.ref}
        onClick={(e) => (onClose ? onClose(e) : false)}
      >
        <div
          className={
            "c-homepage__poetic-content o-layout--full-page u-typo--content"
          }
        >
          <div
            className={
              "o-layout u-columns-24 o-layout--full-page c-homepage__poetic-content-inner"
            }
          >
            <div className={"u-colspan-2 u-colspan-4@desk u-colspan-6@wall"} />
            <div className={"u-colspan-20 u-colspan-16@desk u-colspan-12@wall"}>
              <div className={`o-layout`} ref={this.textRef}>
                <div
                  className={`u-colspan-12 prevent-close`}
                  dangerouslySetInnerHTML={{
                    __html: htmlFromField(showcase.description),
                  }}
                />
              </div>
            </div>
            <div className={"u-colspan-2 u-colspan-4@desk u-colspan-6@wall"} />
          </div>
        </div>
      </div>
    );
  }
}

const HomepageTemplate = ({ data }: HomepageTemplateProp) => {
  const {
    content: { showcases, clientsGif, clientsGifMobile },
  } = data;

  const isTabletOrMobile = useMediaQuery({ maxWidth: 640 });
  const showcase = showcases[0];
  const [isLoaded, setIsLoaded] = useState(false);
  const isDoneAnimating = useRef(false);
  const textRef = useRef(null);
  const poeticContentBlockRef = useRef(null);
  const showingContent = useRef(null);
  const isShowingContent = useRef(false);
  const [counter, setCounter] = useState(0);
  const [isShowreelActive, setIsShowreelActive] = useState(false);

  const animateTextIn = () => {
    gsap
      .timeline()
      .set(".animate-text", {
        opacity: 0,
      })
      .to(
        ".animate-text",
        {
          opacity: 1,
          duration: 1,
          ease: Sine.easeOut,
        },
        2
      );
  };

  const showShowcase = () => {
    gsap
      .timeline()
      .set(".animate-frame", {
        opacity: 0,
        "clip-path": "inset(1px 1px 1px 1px)",
        top: isTabletOrMobile ? "5vh" : 0,
        height: isTabletOrMobile ? "70vh" : "100vh",
      })
      .to(
        ".animate-frame",
        {
          opacity: 1,
          duration: 0.4,
        },
        0.3
      )
      .to(
        ".animate-frame",
        {
          "clip-path": isTabletOrMobile
            ? "inset(12vh 5vw 15vh 5vw)"
            : "inset(1px 15vw)",
          duration: 0.6,
          delay: 1,
          ease: Sine.easeOut,
        },
        0
      )
      .to(
        ".animate-frame",
        {
          "clip-path": isTabletOrMobile
            ? "inset(12vh 5vw 15vh 5vw)"
            : "inset(12vh 15vw)",
          duration: 0.6,
          delay: 0.8,
          ease: Sine.easeOut,
          onComplete: () => {
            isDoneAnimating.current = true;
          },
        },
        1
      );

    animateTextIn();

    gsap
      .timeline()
      .set(".animate-text div", {
        y: 160,
      })
      .to(
        ".animate-text div",
        {
          duration: 0.6,
          y: 0,
          delay: 1,
          ease: Sine.easeOut,
        },
        1
      );

    gsap
      .timeline()
      .set(".c-logo__svg", {
        fill: "white",
      })
      .to(
        ".c-logo__svg",
        {
          fill: "black",
          duration: 1,
          ease: Sine.easeOut,
        },
        2
      );
  };

  const hideShowcase = () => {
    isDoneAnimating.current = false;
    gsap
      .timeline()
      .to(".animate-frame", {
        "clip-path": isTabletOrMobile
          ? "inset(12vh 5vw 15vh 5vw)"
          : "inset(1px 1px)",
        duration: 1,
        ease: Sine.easeOut,
      })
      .to(".animate-frame", {
        opacity: 0,
        duration: 1,
        ease: Sine.easeOut,
      });

    gsap
      .timeline()
      .to(".animate-text", {
        opacity: 1,
        duration: 0.6,
        ease: Sine.easeOut,
      })
      .to(".animate-text div", {
        duration: 0.6,
        y: 200,
        ease: Sine.easeOut,
      });
  };

  const showPoeticContent = (contentRef) => {
    trigger("cursor:state", Cursor.CLOSE);
    contentRef.current.animateIn({ position: 0.4 });
    showingContent.current = contentRef.current;
    isShowingContent.current = true;
  };

  const closePoeticContent = (e) => {
    const target = e.target;
    const parentTarget = target.parentNode;
    const shouldClose =
      !target.classList.contains("prevent-close") &&
      !parentTarget.classList.contains("prevent-close");

    if (!shouldClose) return;

    trigger("cursor:state", Cursor.THUNDER);

    if (showingContent && showingContent.current)
      showingContent.current.animateOut({ position: 0 });

    isShowingContent.current = false;
  };

  const onClick = () => {
    showPoeticContent(poeticContentBlockRef);
  };

  useEffect(() => {
    setCounter(counter + 1);
    window.dispatchEvent(new Event("resize"));
    showShowcase();
  }, [isTabletOrMobile]);

  useEffect(() => {
    // console.log('isLoaded=', isLoaded)
    if (isLoaded) showShowcase();
  }, [isLoaded]);

  useEffect(() => {
    on("page:out", hideShowcase);

    const onResize = () => {
      animateTextIn();
    };

    window.addEventListener("resize", onResize);

    return () => {
      off("page:out", hideShowcase);
      window.removeEventListener("resize", onResize);
    };
  }, []);

  useEffect(() => {
    const onMouseMove = (e) => {
      if (!isDoneAnimating || !isDoneAnimating.current || isTabletOrMobile)
        return;
      // if (!cursorRef || !cursorRef.current) return;

      const x = e.clientX;
      const y = e.clientY;

      const xMove = 15 + (x / window.innerWidth) * 2;
      const yMove2 = 12 - (y / window.innerHeight) * 1.5;

      const xMoveRound = Math.round(xMove * 100) / 100;
      const yMove2Round = Math.round(yMove2 * 100) / 100;

      gsap.killTweensOf(".animate-frame");
      gsap.timeline().to(
        ".animate-frame",
        {
          "clip-path": isTabletOrMobile
            ? "inset(12vh 5vw 12vh 5vw)"
            : `inset(${yMove2Round}vh ${xMoveRound}vw)`,
          duration: 0.3,
        },
        0
      );
    };

    window.addEventListener("mousemove", onMouseMove);

    return () => {
      window.removeEventListener("mousemove", onMouseMove);
    };
  }, []);

  return (
    <>
      <Helmet>
        <title>{getPageTitle({ title: "" })}</title>
      </Helmet>

      <Navigation transition={true} />
      <Logo />
      <div className={"c-homepage--showreel"}>
        <Link to={"#"} className={"o-link u-font-family--secondary"}>
          Showreel
        </Link>
      </div>

      <Showreel setIsActive={setIsShowreelActive} isActive={isShowreelActive} />

      <PoeticContentBlock
        key={"poetic"}
        ref={poeticContentBlockRef}
        showcase={showcase}
        primaryColor={COLOR_ENUM.BLACK}
        secondaryColor={COLOR_ENUM.WHITE}
        logoColorFrom={COLOR_ENUM.BLACK}
        logoColorTo={COLOR_ENUM.WHITE}
        onClose={closePoeticContent}
        cursorColor={COLOR_ENUM.BLUE_LIGHT}
      />

      <div className={`c-homepage--showcase`}>
        <div className={"c-homepage--showcase__title"}>
          <div
            className={"c-homepage--showcase_frame animate-frame"}
            onMouseEnter={() => {
              trigger("cursor:click");
            }}
            onMouseOut={() => {
              trigger("cursor:unclick");
            }}
            onClick={onClick}
          >
            <div
              className={`c-homepage--showcase__first-text mobile animate-text u-hidden@lap`}
            >
              <span>
                {showcase.textTop} {showcase.textBottom}
              </span>
            </div>
            <Textfit
              className={`c-homepage--showcase__first-text animate-text u-hidden@until-lap`}
              mode="single"
              forceSingleModeWidth={true}
              max={400}
              ref={textRef}
            >
              <span>
                {showcase.textTop} {showcase.textBottom}
              </span>
            </Textfit>
          </div>
        </div>

        <div className={"c-homepage--showcase_frame animate-frame"}>
          <MediaBlock
            item={showcase.image}
            additionalClasses={"o-object-fit--cover media"}
            onLoaded={() => {
              setIsLoaded(true);
            }}
          />
        </div>

        <div className={`c-homepage--showcase__title-bg u-hidden@until-lap`}>
          <Textfit
            className={`c-homepage--showcase__second-text animate-text u-hidden@until-lap`}
            mode="single"
            forceSingleModeWidth={true}
            max={400}
          >
            <span>
              {showcase.textTop} {showcase.textBottom}
            </span>
          </Textfit>
        </div>

        <div className={`c-homepage--showcase__title-bg mobile u-hidden@lap`}>
          <div
            className={`c-homepage--showcase__second-text mobile animate-text u-hidden@lap`}
          >
            <span>
              {showcase.textTop} {showcase.textBottom}
            </span>
          </div>
        </div>

        <ShowreelButton setIsActive={setIsShowreelActive} />

        <ClientCaroussel
          gif={clientsGif.file.url}
          gifMobile={clientsGifMobile.file.url}
        />
      </div>
    </>
  );
};

export default HomepageTemplate;
