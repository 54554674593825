import * as React from "react";
import {useRef, useState} from "react";

interface MediaBlockProps {
    item: any,
    additionalClasses?: string,
    onLoaded?: any,
    onMouseOverCallback?: any,
    placeholder?: any
}

const MediaBlock = ({item, additionalClasses, onLoaded, onMouseOverCallback,placeholder}: MediaBlockProps) => {

    const [isMuted, setIsMuted] = useState(true);
    const videoRef = useRef(null);

    const hasAudio = () => {
        if (!videoRef || !videoRef.current) return false
        const video = videoRef.current;

        return video.mozHasAudio ||
            Boolean(video.webkitAudioDecodedByteCount) ||
            Boolean(video.audioTracks && video.audioTracks.length);
    }

    const sendCallback = (eventName) => {
        if (!hasAudio()) return
        if (onMouseOverCallback) onMouseOverCallback(new MouseEvent(eventName))
    }

    const onMouseOver = () => {
        sendCallback(isMuted ? 'cursor_unmute' : 'cursor_mute')
    }

    const onMouseOut = () => {
        sendCallback('cursor_default')
    }

    const onClick = () => {
        if (!hasAudio()) return
        setIsMuted(!isMuted)
        sendCallback(!isMuted ? 'cursor_unmute' : 'cursor_mute')
    }

    const onLoadedCallback = () => {
        if (onLoaded) onLoaded()
    }

    const hasPlaceholder = typeof placeholder !== 'undefined' &&
        placeholder != null &&
        typeof placeholder.gatsbyImageData != 'undefined' &&
        (placeholder.gatsbyImageData?.images?.fallback?.src) !== null;

    const isImage = /^image/.test(item.file.contentType)
    return isImage ? (
        <img src={item.file.url} className={`o-object-fit--cover ${additionalClasses ? additionalClasses : ''}`} crossOrigin="anonymous" />
    ) : (
        <video muted={isMuted} autoPlay={true} loop={true} playsInline={true}
               preload={"auto"}
               data-autoplay
               className={additionalClasses}
               onLoadedData={onLoadedCallback}
               onMouseOver={onMouseOver}
               onMouseOut={onMouseOut}
               onClick={onClick}
               placeholder={hasPlaceholder ? placeholder.gatsbyImageData?.images?.fallback?.src : ''}
                ref={videoRef} crossOrigin="anonymous">
            <source src={item.file.url} type={item.file.contentType}/>
        </video>
    )
}

export default MediaBlock;
